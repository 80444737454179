<template>
    <div class="search-box">
        <div class="input-box">
            <input type="text" placeholder="请输入关键词">
        </div>
        <div class="search-btn">
            <svg class="search-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"  ><path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" ></path></svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchBox"
    }
</script>

<style lang="stylus" scoped>
.search-box
    width 100%
    margin-top 2rem
    height 3rem
    background-color #808080
    padding 2px 2px
    display flex
    .search-btn
        width 2.4rem
        height 100%
        background-color #4b4b4b
        text-align center
        line-height 2.4rem
        .search-icon
            width 1.6rem
            height 1.6rem
            vertical-align  middle
            fill white
    .input-box
        flex 1
        height 100%
        input
            width 100%
            height 100%
            background-color #808080
            border none
            color white
        input::-webkit-input-placeholder
                  color white
        input::-moz-input-placeholder
                  color white
        input::-ms-input-placeholder
                  color white
</style>