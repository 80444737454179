<template>
    <div class="container">
        <div class="container-box">
            <div class="box" style="width:200px;text-align:-webkit-center;">
                <el-image :src="require('@/assets/images/logo.png')" style="width: 100%"></el-image>
            </div>
<!--            <div class="box" style="width:150px" >-->
<!--                <div class="title">-->
<!--                    <span>关于我们</span><br>-->
<!--                    <span style="font-size: 11px;color: #424344">ABOUT US</span>-->
<!--                </div>-->
<!--                <div class="content">-->
<!--                    <div class="link-box">-->
<!--                        <div class="link-info" v-for="item in aboutUsLinks" :key="item.name"> <a :href="item.url">{{item.name}}</a></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="box" style="width:150px" >-->
<!--                <div class="title">-->
<!--                    <span>产品服务</span><br>-->
<!--                    <span style="font-size: 11px;color: #424344">PRODUCT SERVICE</span>-->
<!--                </div>-->
<!--                <div class="content">-->
<!--                    <div class="link-box">-->
<!--                        <div class="link-info" v-for="item in productServiceLinks" :key="item.name"> <a :href="item.url">{{item.name}}</a></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="box" style="width:150px" >-->
<!--                <div class="title">-->
<!--                    <span>项目案例</span><br>-->
<!--                    <span style="font-size: 11px;color: #424344">PROJECT CASE</span>-->
<!--                </div>-->
<!--                <div class="content">-->
<!--                    <div class="link-box">-->
<!--                        <div class="link-info" v-for="item in productServiceLinks" :key="item.name"> <a :href="item.url">{{item.name}}</a></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="box">
                <div class="title" >
                    <span>联系方式</span><br>
<!--                    <span style="font-size: 11px;color: #424344">CONTACT US</span>-->
                </div>
                <div class="content">
                    <p>{{cantact.tel}}</p>
                    <p>{{cantact.connect}}</p>
                    <p>{{cantact.addr}}</p>
                </div>
            </div>
            <div class="box" style="width:200px">
                <el-image :src="require('@/assets/images/qr_code.png')" style="width: 70%"></el-image>
                <p style="color:#eeeeee;padding-left: 40px">关注公众号</p>
            </div>
<!--            <div class="box" style="text-align: right">-->
<!--                <div class="title" >-->
<!--                    <span>团队介绍/TEAM</span>-->
<!--                </div>-->
<!--                <div class="content">-->
<!--                    <p>{{desc}}</p>-->
<!--                    <search-box/>-->
<!--                </div>-->
<!--            </div>-->

<!--            <el-col :span="5"></el-col>-->
        </div>
        <div style="text-align: center;color: #888888;border-top:solid 1px;padding-top: 10px">Copyright © {{year}}重庆泷通科技有限公司 | Powered by 重庆泷通科技有限公司</div>
    </div>
</template>

<script>
    import SearchBox from "./SearchBox"
    export default {
        name: "Buttom",
        data:function () {
            return {
                aboutUsLinks:[
                    {
                        url:"http://www.baidu.com",
                        name:'产品介绍'
                    },
                    {
                        url:"http://www.baidu.com",
                        name:'企业环境'
                    },
                    {
                        url:"http://www.baidu.com",
                        name:'企业历史'
                    }
                ],
                productServiceLinks:[
                    {
                        url:"http://www.baidu.com",
                        name:'产品开发'
                    },
                    {
                        url:"http://www.baidu.com",
                        name:'品牌设计'
                    },
                    {
                        url:"http://www.baidu.com",
                        name:'网站建设'
                    }
                ],
                cantact:{
                    addr:'地址：重庆市南岸区铜元局江苑路8号英华天元6栋2楼',
                    tel:'电话：62315009',
                    connect:'联系人:  曾美菊'
                },
                desc:'我们来自五湖四海、但共同的目标孕育着同一个理想 出发点不同但目的地一样,年轻的心在这一刻相聚 团结一致、不理不弃,斩破风浪,勇敢起航',
                year:2000
            }
        },
        components:{
            SearchBox
        },
        created() {
            let date =new Date();
            this.year=date.getFullYear();
        }
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    height auto
    padding 10px
    background-color #383838
    .container-box
        width 1000px
        height 100%
        margin 0 auto
        display flex
        flex-direction row
        justify-content space-around
        align-content center
        align-items center
        .box
            width 300px
            height 100%
            .title
                color white
                font-weight  bold
                line-height  24px
                font-size 16px
                opacity 1
                letter-spacing 1px
            .content
                padding 10px 0px
                color white
                font-size 10px
                line-height 20px
                p
                    font-size 10px
                    line-height 20px
                    margin 0
                .link-box
                    width 100%
                    height 100%
                    display flex
                    flex-direction row
                    flex-wrap wrap
                    justify-content left
                    align-content left
                    .link-info
                        width 100%
                        a
                            color white
@media screen and (min-width: 320px)  and (max-width: 1024px)
    .container
        .container-box
            width 100%

</style>
