<template>
    <div>
        <Header type="1" url="/Transport"></Header>
        <div class="banner">
        </div>

        <div class="content-container">
            <div class="pub-btns-container">
                <div><el-button type="primary" plain @click="goPush">我要发布</el-button></div>
                <div><el-button type="primary" @click="myPush" >我的发布</el-button></div>
            </div>
            <div class="search-container">
                <el-select v-model="query.regionItems" 
					multiple
					collapse-tags
					clearable placeholder="请选择地区">
                    <el-option
                            v-for="item in regionItems"
                            :key="item.id"
							:label="item.name"
							:value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="query.vehicleItems"
					multiple
					collapse-tags
					clearable placeholder="请选择">
                    <el-option
                            v-for="item in vehicleItems"
                            :key="item.id"
							:label="item.name"
							:value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="query.sortTypes" clearable placeholder="排序方式">
                    <el-option
                            v-for="item in sortTypes"
                            :key="item"
                            :value="item">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="clickSearch">搜索</el-button>
            </div>
            <div class="projects" v-infinite-scroll="loadCard" infinite-scroll-disabled="disabled" style="">
				<el-card v-for="item in tableData" class="box-card" shadow="hover" style="width: 90%;margin-left: 50px;margin-bottom: 10px;">
				  <div slot="header" class="clearfix">
				    <span>{{item.title}}</span>
				    <el-button @click="detail(item.id)" style="float: right; padding: 3px 0" type="text">查看详情</el-button>
				  </div>
				  <div class="text item">
				    {{item.regionName}} | {{item.vehicleName}} <br><br>
					{{item.unitName}} <br><br>
					<span style="color: orangered;">{{item.price}}元/车</span>
				  </div>
				</el-card>
				<p v-if="loading" style="text-align: center;">加载中...</p>
				<p v-if="noMore" style="text-align: center;">没有更多了</p>
            </div>
        </div>
        <buttom></buttom>
		
		<el-dialog title="运力发布" :visible.sync="dialogVisible" width="35%" center :before-close="handleClose">
		  <span>
			  <el-form ref="form"  :inline="true" :model="form" :rules="rules" label-width="80px">
				  <el-form-item label="运力标题" prop="title">
					  <el-input class="form-input" v-model="form.title"></el-input>
				  </el-form-item>
				  <el-form-item label="服务地区" prop="regionId">
					  <el-select  class="form-input" v-model="form.regionId" clearable placeholder="请选择服务地区">
						  <el-option
					        v-for="item in regionItems"
					        :key="item.id"
					        :label="item.name"
					        :value="item.id">
					      </el-option>
					  </el-select>
				  </el-form-item>
				  <el-form-item label="车辆数量" prop="vehicleCount">
					  <el-input class="form-input" v-model="form.vehicleCount"></el-input>
				  </el-form-item>
				  <el-form-item label="运力标签" prop="vehicleId">
					  <el-select  class="form-input" v-model="form.vehicleId" clearable placeholder="请选择运力标签">
						  <el-option
							v-for="item in vehicleItems"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						  </el-option>
					  </el-select>
				  </el-form-item>
				  <el-form-item label="运价" prop="price">
					  <el-input class="form-input" v-model="form.price"></el-input>
				  </el-form-item>
				
				 <el-form-item label="服务时间">
					  <el-time-select style="width: 100px;" @change="serviceTime1Change"
					      placeholder="起始时间"
					      v-model="serviceTime1"
					      :picker-options="{
					        start: '00:00',
					        step: '00:15',
					        end: '24:00'
					      }">
					    </el-time-select>
						~
						<el-time-select style="width: 100px;" @change="serviceTime2Change"
						    placeholder="结束时间"
						    v-model="serviceTime2"
						    :picker-options="{
						      start: '00:00',
						      step: '00:15',
						      end: '24:00',
						      minTime: serviceTime1
						    }">
						  </el-time-select>
				  </el-form-item>
				  
				  <el-form-item label="是否有效" prop="isValid">
						<el-switch v-model="form.isValid"
						 active-text="有效"
						 inactive-text="无效"></el-switch>
				  </el-form-item>
				  
				  <el-form-item label="详细描述" prop="detailDesc">
						<el-input type="textarea" v-model="form.detailDesc" style="width: 520px;"></el-input>
				  </el-form-item>
			  </el-form>
		  </span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="formSubmit">确 定</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog :visible.sync="detailDialog" width="45%">
			<div style="padding: 0 3%;">
			    <el-row class="userInfo_list">
					<div class="grid-content">
						<div class="lost-title">{{project.title}}</div><br>
					</div>
					<el-row>
						<el-col :span="12">
							<div class="grid-content">
								<div style="font-size: 18px;color: orangered;">{{project.price}}元/车</div>
							</div>
						</el-col>	
					</el-row>
			    </el-row>
				<div class="grey-line">
					<el-row>
						<el-col :span="5">
							<div class="grid-content">
								<div class="complaint-title">区域：</div>
								<div class="title-content">{{project.regionName}}</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="grid-content">
								<div class="complaint-title">车型：</div>
								<div class="title-content">{{project.vehicleName}}</div>
							</div>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col :span="12">
							<div class="grid-content">
								<div class="complaint-title">需车数量：</div>
								<div class="title-content">{{project.vehicleCount}}</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="16">
							<div class="grid-content">
								<div class="complaint-title">服务时间：</div>
								<div class="title-content">{{project.serviceTime}}</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content">
								<div class="title-content">
									<el-button @click="apply(project.id,project.userId)"  type="primary" style="width: 108px;background-color: #073060;border-color: #073060;">申 请</el-button>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="16">
							<div class="grid-content">
								<div class="complaint-title"></div>
								<div class="title-content"></div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content">
								<div class="">更新：</div>
								<div class="title-content">{{project.updateTime}}</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="16">
							<div class="grid-content">
								<div class="complaint-title"></div>
								<div class="title-content">
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content">
								<div class="">浏览：</div>
								<div class="title-content">{{39}}</div>
							</div>
						</el-col>
					</el-row>
					<div style="width: 100%;border: 1px solid #d8d8d8;height: 300px;margin-top: 10px;">
						<div style="margin-top: 2%;margin-left: 3%;">详细描述</div>
						<el-input type="textarea" v-model="project.detailDesc" :rows="12" resize="none" readonly="readonly" style="margin-top: 1%;margin-left: 1%;width: 98%;"></el-input>
					</div>
				</div>
			</div>
		</el-dialog>
    </div>
</template>

<script>
    import Header from "@/components/common/Header";
    import Buttom from "@/components/common/Buttom";
    export default {
        name: "Transport",
        data:function () {
            return {
                query:{
                    regionItems:[],
                    vehicleItems:[],
                    sortType:'',
					pageIndex:1,
					pageRows:5,
					isValid:true
                },
				pageSize:[
				    10,20,30,40,50
				],
				tableData: [],
				pageTotal: 0,
                regionItems:[],
                vehicleItems:[],
                sortTypes:[
                    "发布时间",
                ],
				loading: false,
				dialogVisible:false,
				form:{
					title:'',
					regionId:'',
					vehicleId:'',
					vehicleCount:'',
					price:'',
					serviceTime:'',
					isValid:true,
					detailDesc:'',
				},
				rules: {
					  title: [{ required: true, message: '请输入运力标题', trigger: 'blur' }],
					  regionId: [{ required: true, message: '请选择服务地区', trigger: 'change' }],
					  vehicleCount: [{ required: true, message: '请输入车辆数量', trigger: 'blur' }],
					  vehicleId: [{ required: true, message: '请选择车辆信息', trigger: 'change' }],
					  price: [{ required: true, message: '请输入运价', trigger: 'blur' }],
					  serviceTime: [{ required: true, message: '请选择服务时间', trigger: 'change' }],
					  isValid: [{ required: true, message: '请选择', trigger: 'change' }],
					  detailDesc: [{ required: true, message: '请输入详细描述', trigger: 'blur' }]
				},
				serviceTime1:'',
				serviceTime2:'',
				detailDialog:false,
				project:{},
            }
        },
        components:{
            Header,
            Buttom,
        },
		created() {
			this.clickSearch();
			this.initRegionItem();
			this.initVehicleItem();
		},
		computed:{
			noMore () {
				return this.tableData.length >=this.pageTotal;
			},
			disabled () {
				return this.loading || this.noMore
			}
		},
		methods:{
			initRegionItem:function(){
				this.HTTP.get('/base/initRegionItem').then(res=>{
					if(res.data.code==1){
						this.regionItems = res.data.rows;
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			initVehicleItem:function(){
				this.HTTP.get('/base/initVehicleItem').then(res=>{
					if(res.data.code==1){	
						this.vehicleItems = res.data.rows;
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			clickSearch:function(){
				this.query.pageIndex = 1;
				this.handleSearch();
			},
			handleSearch: function() {
				this.HTTP.post('/capacity/list', this.query).then(res => {
					if (res.data.code == 1) {
						this.tableData = res.data.rows
						this.pageTotal = res.data.counts
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err => {
					this.$message.error(err);
				})
			},
			handlePageChange: function(e) {
				this.query.pageIndex = e
				this.handleSearch()
			},
			pageChange: function(type) {
				if (type == 1) {
					this.query.pageIndex -= 1;
				} else {
					this.query.pageIndex += 1;
				}
				this.handleSearch()
			},
			handleSizeChange:function (val) {
				this.query.pageRows = val
			    this.handleSearch()
			},
			loadCard:function(){
				this.loading = true;
				setTimeout(() => {
					  this.query.pageIndex++;
					  this.HTTP.post('/capacity/list', this.query).then(res => {
					  	if (res.data.code == 1) {
							let rows = res.data.rows;
							rows.forEach(item=>{
								this.tableData.push(item);
							})
					  		this.pageTotal = res.data.counts;
					  	}else{
					  		this.$message.error(res.data.message);
					  	}
					  }).catch(err => {
						this.$message.error(err);
					  })
					  this.loading = false
				}, 2000)
			},
			checkUserInfo:function(){
				let userInfo = sessionStorage.getItem("userinfo");
				let json = JSON.parse(userInfo);
				if(!json.isCorrect){
					this.$confirm('您的公司信息尚未完善, 是否立即前往完善资料信息?', '提示', {
					          confirmButtonText: '确定',
					          cancelButtonText: '取消',
					          type: 'info'
					}).then(() => {
					  this.$router.push({name:'MyCenter', params:{showFlag: 5}});
					}).catch(()=>{
					})
					return false;
				}else{
					return true;
				}
			},
			detail:function(id){
				if(this.checkUserInfo()){
					//调用详情接口
					this.HTTP.get('/capacity/detail?id='+id).then(res=>{
						if(res.data.code==1){
							this.detailDialog = true;
							this.project = res.data.data;
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}
			},
			goPush:function(){
				if(this.checkUserInfo()){
					this.dialogVisible = true;
					this.resetForm();
				}
			},
			serviceTime1Change:function(e){
				if(e){
					this.form.serviceTime = e;
				}
			},
			serviceTime2Change:function(e){
				if(!this.serviceTime1){
					this.$message.warning('请先选择起始时间');
					return false;
				}
				if(e){
					this.form.serviceTime = this.serviceTime1+"~"+e;
				}
			},
			resetForm:function(){
				this.form = {
					id:'',
					title:'',
					regionId:'',
					vehicleId:'',
					vehicleCount:'',
					price:'',
					serviceTime:'',
					isValid:true,
					detailDesc:'',
				};
				this.serviceTime1 = '';
				this.serviceTime2 = '';
				if(this.$refs['form']!= undefined){
					this.$refs['form'].resetFields();
				}
			},
			formSubmit:function(){
				this.$refs['form'].validate((valid) => {
					  if (valid) {
						 this.HTTP.post('/capacity/input',this.form).then(res=>{
							  if(res.data.code==1){
								  this.$message.success('发布成功');
								  this.dialogVisible = false;
								  this.clickSearch();
								  this.resetForm();
							  }else{
								  this.$message.error(res.data.message);
							  }
						 }).catch(err=>{
							  this.$message.error(err);
						 })
					  }
				});
			},
			myPush:function(){
				this.$router.push({name:'MyCenter', params:{showFlag: 6,activeName:'second'}});
			},
			apply:function(id,inputId){
				this.$confirm('提交申请后需等待平台分配，是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					let req = {
						type:2,
						id:id,
						inputId:inputId
					}
					this.HTTP.post('/apply/submitApply',req).then(res=>{
						if(res.data.code ==1){
							this.$alert('运力申请提交成功，请等待平台分配或前往个人中心查看', '提示', {confirmButtonText: '确定',type: 'success'});
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				})
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
				  .then(_ => {
					done();
				  })
				  .catch(_ => {});
			}
		}
    }
</script>

<style lang="stylus" scoped>

.banner
    width 100%
    height 60rem
    background url("../../assets/images/transport-banner.png") no-repeat center
    background-size 100% 100%
.content-container
    width 100rem
    height 100rem
    margin 0 auto
    margin-top -20rem
    box-sizing border-box
    background-color #e2f4ff
    h2
        text-align center
    .search-container
        box-sizing border-box
        background-color lightgrey
        height 5rem
        padding 1rem
        display flex
        justify-content space-around
.pub-btns-container
    width 20rem
    height 10rem
    box-sizing border-box
    background-color white
    float right
    margin-right -22rem
    display flex
    flex-direction column
    padding 1rem 2rem
    justify-content space-between
    div
        width 100%
        text-align center
	.projects
		margin-top 20px
		overflow-y: auto
		width 1000px
		height 927px
@media screen and (min-width: 320px)  and (max-width: 1024px)
	.content-container
		width 100%
	.pub-btns-container
		float none
		margin 0
		width 100%
		height 5rem
		padding 1rem
		flex-direction row
	
</style>

<style scoped>
	.form-input{
		width: 215px;
	}
	.grey-line .el-row{
		/* border-bottom: 1px solid #dcdfe6; */
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}
	
	.userInfo_list .grid-content {
	    line-height: 20px;
	}
	
	.userInfo_list .lost-title {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 25px;
	}
	
	.userInfo_list .title-content {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	    margin-left: 5px;
	}
	
	.grid-content {
	    margin: 10px 0;
	    display: flex;
	    flex-direction: row;
	    align-items: center !important;
	}
	
	.complaint-title1 {
	    align-items: center;
	    margin-right: 5px;
	    font-size: 16px;
	    display: flex;
		font-weight: bolder;
		color: #1f2f3d;
	    white-space: nowrap;
	}
	
	.title-content {
	    display: flex;
	    align-items: center;
	}
	
</style>